<div class="d-flex align-items-center">    
    <div class="profile-name">Hi, <div class="notranslate" style="float: right;">&nbsp;{{(profile$ | async)?.displayName}}</div></div>
    <div class="profile">
        <a id="mectrl_viewAccount" class="mectrl_resetStyle mectrl_link mectrl_truncate"
        aria-describedby="mectrl_viewAccount_desc"
         href="{{profileLink}}&login_hint={{(profile$ | async)?.mail}}" target="_blank">
         <img class="profile-img" src="{{profilePicture}}" alt="">  
        </a>             
    </div>
</div>
