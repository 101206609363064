// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment =  {
//   production: false,
//   scopeUri: ['openid','api://a7197d63-80bf-45e4-a310-a6dd15ab3fd8/AccessAPI'],
//   tenantId: '1588262d-23fb-43b4-bd6e-bce49c8e6186',
//   uiClienId: '5de2616b-7e47-49a4-8664-6bb66d1f79d6',
//   apiBaseUrl: 'https://localhost:44397',
//   redirectUri: 'http://localhost:4200',
//   downloadFilePath: 'Downloads',
//   instrumentationKey: 'ea086ddf-dba5-4556-9066-41addd9e08c3'
// };

// export const environment = {  
//   production: false, 
//   scopeUri:['openid','api://40803bf6-2d68-4789-809f-f5dce85c02a6/AccessAPI'], 
//   tenantId:   'iom.int',
//   uiClienId: '08cec244-ccc5-4f55-a9b7-3518c224454f',
//   apiBaseUrl: 'https://localhost:44397',
//   redirectUri: 'http://localhost:4200',
//   downloadFilePath: 'Downloads'
// };

//export const environment = {  
//  production: false, 
//  scopeUri: ['openid','api://12ae9e38-1d18-4740-844a-96e9f288a52e/AccessAPI'], //
//  tenantId:   'fe014feb-a845-44a4-828e-62cb3f4d625f',//
//  uiClienId: 'f53b8625-472e-42e1-b1ec-7a0300f2de2e',//
//  apiBaseUrl: 'https://localhost:44397',
//  PDADApiBaseUrl: 'https://localhost:44347',
//  knowledgeSharingApiBaseUrl: 'https://localhost:44307',
//  analyticsApiBaseUrl: 'https://localhost:44315',
// // apiBaseUrl: 'https://devpolicyhub-api.iom.int/virtualworkspace/',
//  redirectUri: 'http://localhost:4200',
//  downloadFilePath: 'Downloads',
//  instrumentationKey: 'ea086ddf-dba5-4556-9066-41addd9e08c3',
//  chatbotUrl: 'https://webchat.botframework.com/embed/ODG-PolicyHub-ChatbotDEV?s=RNfiTDfh-qc.qzjcKpgGgN39-CPbVT-vllc5nYpeoz80jU1nBoiOKd4'
//  };


export const environment = {
  production: true,
  scopeUri: ['openid','api://cfbbbcb9-9f8d-4c10-80fd-b1497a7554a3/AccessAPI'], 
  tenantId: '1588262d-23fb-43b4-bd6e-bce49c8e6186',
  uiClienId: '6d6e3cd9-15c7-4cd8-a61e-782122f1790f',
  apiBaseUrl: 'https://poem.iom.int/virtualworkspace',
  PDADApiBaseUrl: 'https://poem.iom.int/pdad',
  knowledgeSharingApiBaseUrl: 'https://poem.iom.int/knowledgesharing',
  analyticsApiBaseUrl: 'https://poem.iom.int/analytics',
  redirectUri: 'https://poem.iom.int',
  downloadFilePath: 'Downloads',
  instrumentationKey: '9849670d-68f9-4c7d-b95d-a9397aac044d',
  chatbotUrl: 'https://webchat.botframework.com/embed/ODG-PolicyHub-Chatbot?s=jr410FYODh0.G1vUhBW9-JZrbLNH4hBKarLImX4ncviMpAN7-ZYvxL4',
  azblobSecurityUrl: "?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2033-04-13T11:11:33Z&st=2023-04-13T03:11:33Z&spr=https&sig=RDvTDGj%2BPZEy8Rk26EK6ra459A%2FJYNbGaNuKeEZ2S8o%3D",
  countryAllTemplateLink: "https://iomint.sharepoint.com/sites/MultiLangPOC-IKM/poem/Good%20Practices%20Templates/Forms/AllItems.aspx",
  countryUploadTemplateLink: "https://apps.powerapps.com/play/e/default-1588262d-23fb-43b4-bd6e-bce49c8e6186/a/03359607-2f94-4d7e-a437-63d8573c975a?myFT=gp&tenantId=1588262d-23fb-43b4-bd6e-bce49c8e6186&hint=b9f725b9-6d65-4692-9d4a-8841feb725ea&sourcetime=1709091976531&source=portal",
  innovativeInitiativesFormLink: "https://apps.powerapps.com/play/e/default-1588262d-23fb-43b4-bd6e-bce49c8e6186/a/03359607-2f94-4d7e-a437-63d8573c975a?myFT=ii&tenantId=1588262d-23fb-43b4-bd6e-bce49c8e6186&hint=b9f725b9-6d65-4692-9d4a-8841feb725ea&sourcetime=1709091976531&source=portal",
  landingPageKnowledgePlatforms: "https://iomint.sharepoint.com/teams/InnovationandKnowledgeManagementWorkingGroup/Shared%20Documents/Forms/AllItems.aspx?id=%2fteams%2fInnovationandKnowledgeManagementWorkingGroup%2fShared%20Documents%2fGeneral%20KMI%20Working%20Group%2fIOM%20Guide%20to%20Knowledge%20Platforms&p=true&ct=1693280452569&or=Teams%2DHL&ga=1&LOF=1",
  landingPageInnovativeInitiative: "https://poem.iom.int/knowledge-sharing/good-policy-practices/Good%20Practices%20&%20Lessons%20Learned?tm=22",
  landingPageGoodPractices: "https://poem.iom.int/knowledge-sharing/good-policy-practices/Good%20Practices%20&%20Lessons%20Learned",
  globalRegionCode : "ZHBA01-GLB",
  evaRepositoryUrl : "https://www.evaluationportal.iom.int/EvaRepository",
  ikmspsite : "https://iomint.sharepoint.com/teams/InnovationandKnowledgeManagementWorkingGroup/poem"
};

export const azureLogUrl = {
  searchWorkspaceLogUrl:"https://portal.azure.com#@1588262d-23fb-43b4-bd6e-bce49c8e6186/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F6f717a1e-ac98-4f16-846c-d8a4b100e912%2FresourceGroups%2Fict-p-we-rgp-odgpolicyhub-svc%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fict-p-we-rgp-odgpolicyhub-svc/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA2WNuw5CIRBEe79icivoTKyxMfELjP0KmwtRHlkgROPHC7XdzJycTKGd74FHxeGL4VkYiSIDxmC7huRAGFmetZDlDTSHFiLXRrHgDNqzOh2dnnLtMZKED6MyifW3XIKtl9xTg4GyK6guL62BxxszrcssjmX1P2lC4bRoCWw9SfsBqw%252FbiawAAAA%253D",
  searchPDADLogUrl:"https://portal.azure.com#@1588262d-23fb-43b4-bd6e-bce49c8e6186/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F6f717a1e-ac98-4f16-846c-d8a4b100e912%2FresourceGroups%2Fict-p-we-rgp-odgpolicyhub-svc%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fict-p-we-rgp-odgpolicyhub-svc/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA2WNvQrCMBSFd5%252Fi0KnZBOcIYh9AUNyvyaUJmB9uUkrFhzfp6vYdzl%252BmmZ%252Be14LDF6tjYUQKDGiN4TZdJlzTEqtsuDOJcQMoWlQfuFQKGWfQnMbT0arWL0sIJP7DKHv4kbI3ZR%252BAxmg6jIu8lQJeGxr11ySWpeu%252FUjOFY3ezZ%252BNI6g83hxe%252FrwAAAA%253D%253D",
  searchKnowledgeSharingLogUrl: "https://portal.azure.com#@1588262d-23fb-43b4-bd6e-bce49c8e6186/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F6f717a1e-ac98-4f16-846c-d8a4b100e912%2FresourceGroups%2Fict-p-we-rgp-odgpolicyhub-svc%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fict-p-we-rgp-odgpolicyhub-svc/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA2VOvQrCMBjcfYrDqd0E57o4OlbcP5MjDTQ%252FfEkpig9v4up2x%252F1mcXx47gWHD%252FaFSkQJBKYJx1tM%252B0rriHkR9dFhpqhZjpBoUX1gqRIyLhCXhvPJjq2kbCE085soP%252FM9ZW%252FKNW2xYsJgOhg2XccReL7QUJ9Oaqmd%252F4WaqIxdzZ6m%252FahfqC0l%252FLQAAAA%253D",
  searchUserSessionUrl: "https://portal.azure.com/#@iomint.onmicrosoft.com/resource/subscriptions/6f717a1e-ac98-4f16-846c-d8a4b100e912/resourceGroups/ict-p-we-rgp-odgpolicyhub-svc/providers/Microsoft.Insights/components/ict-p-we-rgp-odgpolicyhub-svc/segmentationUsers"
}

// export const environment = {
//   production: false,
//   scopeUri: ['openid','api://a7197d63-80bf-45e4-a310-a6dd15ab3fd8/AccessAPI'],
//   tenantId: '1588262d-23fb-43b4-bd6e-bce49c8e6186',
//   uiClienId: '5de2616b-7e47-49a4-8664-6bb66d1f79d6',
//   apiBaseUrl: 'https://localhost:44397',
//   PDADApiBaseUrl: 'https://localhost:44347',
//   knowledgeSharingApiBaseUrl: 'https://localhost:44307',
//   analyticsApiBaseUrl: 'https://localhost:44315',
//   redirectUri: 'http://localhost:4200',
//   downloadFilePath: 'Downloads',
//   instrumentationKey: '',
//   chatbotUrl: 'https://webchat.botframework.com/embed/ODG-PolicyHub-ChatbotDEV?s=RNfiTDfh-qc.qzjcKpgGgN39-CPbVT-vllc5nYpeoz80jU1nBoiOKd4'

// };

export const microsoftURL = { 
  graphEndpoint : 'https://graph.microsoft.com/v1.0/me',
  profileLink : 'https://myaccount.microsoft.com/?ref=MeControl'
}

export const redirectionUrl = {
  dgfile:'https://iomint.sharepoint.com/sites/DGPOPE'
  
};



export const PDADUrl = {
  MyIOMUrl:'https://my.iom.int/results-overview',
  MGIUrl:'https://d-mgi.iom.int/countryprofile'
  
};

export const mprUrls = {
  searchMPRWithCriteria : 'https://policyrepository.iom.int/sites/search/Pages/All.aspx?k=',
  searchAllDocuments : 'https://policyrepository.iom.int/Pages/Advance-Search.aspx?itemCode=all',
  searchAllDocumentsWithCriteria : 'https://policyrepository.iom.int/sites/search/Pages/All.aspx?k=',
  strategiesFramework : 'https://policyrepository.iom.int/sites/search/Pages/ITP.aspx',
  strategiesFrameworkWithCriteria : 'https://policyrepository.iom.int/sites/search/Pages/ITP.aspx?k=',
  programGuidingDocuments : 'https://policyrepository.iom.int/sites/search/Pages/PGD.aspx',
  programGuidingDocumentsWithCriteria : 'https://policyrepository.iom.int/sites/search/Pages/PGD.aspx?k=',
  policyAdviceSupport : 'https://policyrepository.iom.int/sites/search/Pages/MPW.aspx',
  policyAdviceSupportWithSupport: 'https://policyrepository.iom.int/sites/search/Pages/MPW.aspx?k=',
}

export const footerUrls = {
  intranet : 'https://intranetportal/en-us/Pages/Home.aspx',
  prima : 'https://primaforall.iom.int',
  internationaldevfund : 'https://developmentfund.iom.int', 
  contactus:'mailto:admin.dev@datamatics.com',
  iominnovation : 'https://www.iom.int/innovation', 
  iomknowledge : 'https://www.iom.int/knowledge'
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
