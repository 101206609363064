import { Component, OnInit } from '@angular/core';
import { mprUrls } from '../../../environments/environment'
import { redirectionUrl } from '../../../environments/environment'

@Component({
  selector: 'app-migration-policy-repository',
  templateUrl: './migration-policy-repository.component.html',
  styleUrls: ['./migration-policy-repository.component.css']
})
export class MigrationPolicyRepositoryComponent implements OnInit {
  searchAllDocuments = mprUrls.searchAllDocuments;
  strategiesFramework = mprUrls.strategiesFramework;
  programGuidingDocuments = mprUrls.programGuidingDocuments;
  policyAdviceSupport = mprUrls.policyAdviceSupport;
  searchAllDocumentsWithCriteria = mprUrls.searchAllDocumentsWithCriteria;
  strategiesFrameworkWithCriteria = mprUrls.strategiesFrameworkWithCriteria;
  programGuidingDocumentsWithCriteria = mprUrls.programGuidingDocumentsWithCriteria;
  policyAdviceSupportWithSupport = mprUrls.policyAdviceSupportWithSupport;
  dgFileUrl = redirectionUrl.dgfile;
  // kfiaUrl = 'https://iomint.sharepoint.com/sites/KnowledgeFairandImpactAwards';
  factSheetUrl = '../assets/documents/POEM Cheatsheet.pdf';

  constructor() {}

  // onDGFilesNavigation() {
  //   window.open(this.kfiaUrl, '_blank');
  // }

  onFactSheetNavigation() {
    window.open(this.factSheetUrl, '_blank');
 }

  ngOnInit(): void {
  }

  isButtonDisabled: string;

  onSearchMPR(searchCriteria) {
    if (searchCriteria !== "") {
      window.open(this.searchAllDocumentsWithCriteria + searchCriteria, '_blank');
    }
  }

  onSearchAllDocuemnts(event, searchCriteria) {
    event.preventDefault();
    if (searchCriteria !== "") {
      window.open(this.searchAllDocumentsWithCriteria + searchCriteria, '_blank');
    }
    else if (searchCriteria === "") {
      window.open(this.searchAllDocuments, '_blank');
    }
  }

  onIOMPolicyStrategies(event, searchCriteria) {
    event.preventDefault();
    if (searchCriteria !== "") {
      window.open(this.strategiesFrameworkWithCriteria + searchCriteria, '_blank');
    }
    else if (searchCriteria === "") {
      window.open(this.strategiesFramework, '_blank');
    }
  }

  onProgrammeGuidingDocuments(event, searchCriteria) {
    event.preventDefault();
    if (searchCriteria !== "") {
      window.open(this.programGuidingDocumentsWithCriteria + searchCriteria, '_blank');
    }
    else if (searchCriteria === "") {
      window.open(this.programGuidingDocuments, '_blank');
    }
  }

  onPolicyAdviceSupport(event, searchCriteria) {
    event.preventDefault();
    if (searchCriteria !== "") {
      window.open(this.policyAdviceSupportWithSupport + searchCriteria, '_blank');
    }
    else if (searchCriteria === "") {
      window.open(this.policyAdviceSupport, '_blank');
    }
  }

  onKMGuideNavigation() {
    window.open("https://iomint.sharepoint.com/teams/InnovationandKnowledgeManagementWorkingGroup/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FInnovationandKnowledgeManagementWorkingGroup%2FShared%20Documents%2FGeneral%20KMI%20Working%20Group%2FIOM%20Guide%20to%20Knowledge%20Platforms&p=true&ct=1693280452569&or=Teams%2DHL&ga=1&LOF=1", '_blank');
  }

}
