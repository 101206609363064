<footer>
    <div class="footer">
        <div class="container position-relative">
            <div class="row py-5">
                <div class="col-lg-5">
                    <div class="mb-3">
                        <a [routerLink]="['../learn-more/learn-more',{'paramKey': 'briefIntrouctionDesc'}]"
                            target="_blank" class="about-link banner-link">
                            <img src="../assets/images/Footer/iom-migration.png" alt="no img">
                        </a>
                    </div>
                    <div>
                        <img src="../assets/images/Footer/poem.png" alt="no img">
                    </div>
                </div>
                <div class="col-lg-3 other-screen-margin">
                    <h1>Help</h1>
                    <ul>
                        <li><a class="notextdec" 
                                href="../assets/documents/POEM Cheatsheet.pdf"
                                target="_blank">Getting started with POEM</a></li>
                        <!-- <li><a href="#" (click)="onContactus($event)">Contact Us</a></li> -->
                        <li><a [routerLink]="['../learn-more/learn-more',{'paramKey': 'briefIntrouctionDesc'}]" target="_self">About Us</a></li>
                        <li><a href="#" (click)="onFeedBackClick($event)">Feedback</a></li>
                        <li><a href="../assets/documents/POEM - Browser Translation Guide.pdf"
                            target="_blank">Translate page using browser</a></li>
                    </ul>
                </div>
                <div class="col-lg-4">
                    <h1>Quick Links</h1>
                    <ul>
                        <li><a class="notextdec" href="#" (click)="onIOMKnowledge($event)">Knowledge at IOM</a></li>
                        <li><a class="notextdec" href="#" (click)="onIOMInnovation($event)">Innovation at IOM</a></li>
                        <li><a href="#" (click)="onIntranet($event)">Intranet</a></li>
                        <li><a href="#" (click)="onPrima($event)">PRIMA</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>