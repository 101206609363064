<!--div class="director-general_profile mb-3" style="cursor: pointer;" (click)="onDGFilesNavigation()">
  <div class="director-general_profile-overlay"></div>
  <div class="position-relative">
    <h2>DG’s Files</h2>
    <span class="notranslate"><h4>AMY E. POPE</h4></span>
    <p>DIRECTOR GENERAL</p>
  </div>
</div-->

<div class="kfia" style="cursor: pointer;">
  <!-- <img class="img-responsive" alt="Knowledge Fair and Impact Awards @ IOM" src="../../../assets/images/KFIA.jpg" /> -->
  <iframe src="assets/videos/How to submit content on POEM.mp4" width="100%" height="360" frameborder="0" scrolling="no" allowfullscreen title="Submit content on POEM.mp4"></iframe>
  
  <!-- <iframe 
  src="assets/videos/POEM_DemoVideo.mp4" 
  width="100%" 
  height="360" 
  frameborder="0" 
  allowfullscreen 
  title="My Video">
</iframe> -->
  
  <!-- <video width="100%" height="360" controls>
    <source src="assets/videos/POEM_DemoVideo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video> -->
</div>
<div class="factsheet">
  <a rel="noopener noreferrer">
    <button class="factsheetBtn" (click)="onFactSheetNavigation()">Discover more</button>
  </a>
</div>

<!--div class="row" style="text-align: center;padding:0px; cursor: pointer;" (click)="onKMGuideNavigation()">
  <img src="../../assets/images/KMGuide/KM Guide_1.png" alt="" style="width: 100%;z-index: -100;position: relative">
</div-->

<!-- <div class="migration-policy_repository">
  <h2>Migration Policy Repository</h2>
  <div class="row">
    <div class="col-lg-6 mb-2">
      <div class="d-flex align-items-center" (click)="onSearchAllDocuemnts($event, '')">
        <div class="migration-policy_repository-box">
          <img src="../../../assets/images/search-white.svg" alt="no img">
        </div>
        <p>Search All Documents</p>
      </div>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="d-flex align-items-center" (click)="onIOMPolicyStrategies($event, '')">
        <div class="migration-policy_repository-box">
          <img src="../../../assets/images/strategies-white.svg" alt="no img">
        </div>
        <p>Strategies/ Frameworks</p>
      </div>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="d-flex align-items-center" (click)="onProgrammeGuidingDocuments($event,'')">
        <div class="migration-policy_repository-box">
          <img src="../../../assets/images/folder-open-white.svg" alt="no img">
        </div>
        <p>Programme Guiding Documents</p>
      </div>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="d-flex align-items-center" (click)="onPolicyAdviceSupport($event, '')">
        <div class="migration-policy_repository-box">
          <img src="../../../assets/images/messages-white.svg" alt="no img">
        </div>
        <p>Policy Advice/ Support</p>
      </div>
    </div>
  </div>
</div> -->