import { Component, OnInit } from '@angular/core';
import {footerUrls} from '../../environments/environment'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  intranet=footerUrls.intranet;
  prima=footerUrls.prima;
  internationaldevfund=footerUrls.internationaldevfund
  contactus=footerUrls.contactus
  iominnovation=footerUrls.iominnovation
  iomknowledge=footerUrls.iomknowledge

  constructor() { }

  ngOnInit(): void {
  }

  onIntranet(event){
    event.preventDefault();
    window.open(this.intranet, '_blank');
  }

  onPrima(event){
    event.preventDefault();
    window.open(this.prima, '_blank');
  }

  onInternationaldevfund(event){
    event.preventDefault();
    window.open(this.internationaldevfund, '_blank');
  }

  onIOMInnovation(event){
    event.preventDefault();
    window.open(this.iominnovation, '_blank');
  }

  onIOMKnowledge(event){
    event.preventDefault();
    window.open(this.iomknowledge, '_blank');
  }

  onContactus(event){
    event.preventDefault();
    window.open(this.contactus, '_blank');
  }

  onFeedBackClick(event) {
    event.preventDefault();
    window.open("https://iom.eu.qualtrics.com/jfe/form/SV_3JyXXe9s5C8lzSK", '_blank');
  }

}
